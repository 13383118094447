export const events = {
	// -- CLICKOUTS -- //
	clickoutResultList: 'Clickout Result List',
	clickoutResultViewMore: 'Clickout Result Modal',

	// -- FEATURES RESULT PAGE -- //
	openPriceCalendar: 'Open PriceCalendar',
	closePriceCalendar: 'Close PriceCalendar',
	openPriceCutter: 'Open PriceCutter',
	closePriceCutter: 'Close PriceCutter',
	openPriceAlert: 'Open PriceAlert',
	closePriceAlert: 'Close PriceAlert',
	setPriceAlert: 'Set PriceAlert',
	changeLanguage: 'Change Language',

	// -- SHARE -- //
	shareResult: 'Share Result',
	shareFavourites: 'Share Favourites',

	// -- SEARCH -- //
	search: 'Search',
	focusSearch: 'Focus Search',
	unfocusSearch: 'Unfocus Search',
	editsearchDestinationFrom: 'Edit Search Destination From',
	editsearchDestinationTo: 'Edit Search Destination To',
	editsearchSwitchDestination: 'Edit Search Destination Switch',
	editsearchDateDeparture: 'Edit Search Date Departure',
	editsearchDateReturn: 'Edit Search Date Return',
	editsearchPassengers: 'Edit Search Passengers',
	editsearchTicketType: 'Edit Search Ticket Type',
	editsearchTripType: 'Edit Search Trip Type',
	editsearchDirectFlightOnlyEnabled: 'Edit Search Direct Flight Only Enabled',
	editsearchDirectFlightOnlyDisabled: 'Edit Search Direct Flight Only Disabled',
	editsearchRebookableEnabled: 'Edit Search Rebookable Enabled',
	editsearchRebookableDisabled: 'Edit Search Rebookable Disabled',
	editsearchFlexibleDatesEnabled: 'Edit Search Flexible Dates Enabled',
	editsearchFlexibleDatesDisabled: 'Edit Search Flexible Dates Disabled',
	editsearchBaggageEnabled: 'Edit Search Baggage Enabled',
	editsearchBaggageDisabled: 'Edit Search Baggage Disabled',
	editsearchCabinBaggageEnabled: 'Edit Search Cabin Baggage Enabled',
	editsearchCabinBaggageDisabled: 'Edit Search Cabin Baggage Disabled',
	editsearchCO2CompensateEnabled: 'Edit Search CO2 Compensate Enabled',
	editsearchCO2CompensateDisabled: 'Edit Search CO2 Compensate Disabled',
	calendarsearch: 'Calendar Search',
	cuttersearch: 'PriceCutter Search',
	refreshsearch: 'Refresh Search',

	// -- Favourites -- //
	addFavourite: 'Add Favourite',
	refreshFavourite: 'Refresh Favourite',
	removeFavourite: 'Remove Favourite',

	// -- VIEW MORE -- //
	viewmoreResult: 'View More Result',
	viewlessResult: 'View Less Result',
	viewmoreOTAs: 'View More OTAs',
	viewlessOTAs: 'View Less OTAs',

	// -- READ MORE -- //
	readmoreBestSorting: 'Read More Best Sorting',

	// -- FILTERS -- //
	filterRebookableEnabled: 'Filter Rebookable Enabled',
	filterRebookableDisabled: 'Filter Rebookable Disabled',
	filterBaggageEnabled: 'Filter Baggage Enabled',
	filterBaggageDisabled: 'Filter Baggage Disabled',
	filterCabinBaggageEnabled: 'Filter Cabin Baggage Enabled',
	filterCabinBaggageDisabled: 'Filter Cabin Baggage Disabled',
	filterCO2CompensateEnabled: 'Filter CO2 Compensate Enabled',
	filterCO2CompensateDisabled: 'Filter CO2 Compensate Disabled',
	filterPaymentMethodEnabled: 'Filter Payment Method Enabled',
	filterPaymentMethodDisabled: 'Filter Payment Method Disabled',
	filterStopsEnabled: 'Filter Stops Enabled',
	filterStopsDisabled: 'Filter Stops Disabled',
	filterDirectFlightOnlyEnabled: 'Filter Direct Flight Only Enabled',
	filterDirectFlightOnlyDisabled: 'Filter Direct Flight Only Disabled',
	filterNoLongTransferTimeEnabled: 'Filter No Long Transfer Time Enabled',
	filterNoLongTransferTimeDisabled: 'Filter No Long Transfer Time Disabled',
	filterTravelTimeEnabled: 'Filter Travel Time Enabled',
	filterTravelTimeDisabled: 'Filter Travel Time Disabled',
	filterDepartureTimeEnabled: 'Filter Departure Time Enabled',
	filterDepartureTimeDisabled: 'Filter Departure Time Disabled',
	filterArrivalTimeEnabled: 'Filter Arrival Time Enabled',
	filterArrivalTimeDisabled: 'Filter Arrival Time Disabled',
	filterActiveTimetableEnabled: 'Filter Active Timetable Enabled',
	filterActiveTimetableDisabled: 'Filter Active Timetable Disabled',
	filterAirlinesEnabled: 'Filter Airlines Enabled',
	filterAirlinesDisabled: 'Filter Airlines Disabled',
	filterBlacklistedEnabled: 'Filter Blacklisted Enabled',
	filterBlacklistedDisabled: 'Filter Blacklisted Disabled',
	filterAllianceEnabled: 'Filter Alliance Enabled',
	filterAllianceDisabled: 'Filter Alliance Disabled',
	filterAirportsDepartureEnabled: 'Filter Airports Departure Enabled',
	filterAirportsDepartureDisabled: 'Filter Airports Departure Disabled',
	filterAirportsArrivalEnabled: 'Filter Airports Arrival Enabled',
	filterAirportsArrivalDisabled: 'Filter Airports Arrival Disabled',
	filterAirportsStopsEnabled: 'Filter Airports Stops Enabled',
	filterAirportsStopsDisabled: 'Filter Airports Stops Disabled',
	filterTravelCompanyEnabled: 'Filter Travel Company Enabled',
	filterTravelCompanyDisabled: 'Filter Travel Company Disabled',
	filterHideForeignTravelAgenciesEnabled: 'Filter Hide Foreign Travel Agencies Enabled',
	filterHideForeignTravelAgenciesDisabled: 'Filter Hide Foreign Travel Agencies Disabled',
	resetFilters: 'Reset Filters',

	// -- SORTING -- //
	sortCheapest: 'Sort Cheapest',
	sortQuickest: 'Sort Quickest',
	sortBest: 'Sort Best',

	// -- RESULTS DISPLAY -- //
	changedisplayList: 'Change Display List',
	changedisplayTimetable: 'Change Display Timetable',
	changedisplayTimeline: 'Change Display Timeline',

	// -- VIEWPORT SHOWN/OBSERVED EVENTS -- //
	observeNoResults: 'Observe No Results',
	observeAncientSearch: 'Observe Ancient Search',
	observeOldSearch: 'Observe Old Search',
	observeKallebakom: 'Observe Kallebakom',
	observeAdvertisement: 'Observe Advertisement',

	// -- SCROLL -- //
	scrollMoreResults: 'Scroll More Results',
	scrollFilterList: 'Scroll Filter List',
	scrollIndex: 'Scroll Index',

	// -- COOKIES -- //
	acceptCookies: 'Accept Cookies',
	declineCookies: 'Decline Cookies',
	manageCookies: 'Manage Cookies',

	// -- KALLEBAKOM CLICK-- //
	kalleBakomClickAirportTransfer: 'Click Airport Transfer',
	kalleBakomClickArlandaExpress: 'Click Arlanda Express',
	kalleBakomClickBooking: 'Click Booking.com',
	kalleBakomClickForex: 'Click Forex',
	kalleBakomClickRentalCar: 'Click Rental Cars',
	kalleBakomClickAiralo: 'Click Airalo'
};

export const eventCategories = {
	// -- CLICKOUTS -- //
	clickout: 'Clickout',

	// -- FEATURES RESULT PAGE -- //
	// priceCalendar: 'PriceCalendar',
	// priceCutter: 'PriceCutter',
	// priceAlert: 'PriceAlert',
	// favourite: 'Favourite',
	feature: 'Feature',
	language: 'Language',

	// -- SHARE -- //
	share: 'Share',

	// -- SEARCH -- //
	search: 'Search',

	// -- VIEW MORE -- //
	viewmore: 'View More',

	// -- READ MORE -- //
	readmore: 'Read More',

	// -- FILTERS -- //
	filter: 'Filters',

	// -- SORTING -- //
	sort: 'Sort',

	// -- RESULTS DISPLAY -- //
	changeDisplay: 'Change Display',

	// -- VIEWPORT SHOWN/OBSERVED EVENTS -- //
	observe: 'Observe',

	// -- SCROLL -- //
	scroll: 'Scroll',

	// -- COOKIES -- //
	cookies: 'Cookies',

	// -- KALLEBAKOM -- //
	kallebakom: 'Kallebakom'
};
